import React from "react";
import styles from "./ScrollAnimation.css";
import "animate.css";

function ScrollAnimation() {
  return (
    <div>
      <div className="arrow animate__animated  animate__fadeInDown animate__delay-2s">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default ScrollAnimation;
