import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import styles from "./Navbar.css";
function Navbar() {
  return (
    <div>
      <div className="">
        <div
          id="navBar"
          className="d-flex justify-content-evenly bg-dark position-fixed w-100"
        >
          <div className="">
            <Link
              activeClass="active"
              to="welcome"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="navbar-link"
            >
              <i className="bi bi-house-door-fill SideBar-icon"></i>
              <span className="SideBar-text">Welcome</span>
            </Link>
          </div>
          <div className="">
            <Link
              activeClass="active"
              to="Projects"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="navbar-link"
            >
              {" "}
              <i className="bi bi-file-code-fill  SideBar-icon"></i>
              <span className="SideBar-text">Projects</span>
            </Link>
          </div>
          <div className="">
            <Link
              activeClass="active"
              to="About-Me"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="navbar-link"
            >
              <i className="bi bi-file-person-fill SideBar-icon"></i>
              <span className="SideBar-text">About Me</span>
            </Link>
          </div>
          <div className="d-flex">
            <Link
              activeClass="active"
              to="Contact-Me"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="navbar-link "
            >
              {" "}
              <i className="bi bi-chat-left-dots-fill SideBar-icon"></i>
              <span className="SideBar-text">Contact Me</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
