import React, { useState } from "react";
import { Carousel, Col, Container, Modal, Row } from "react-bootstrap";
import ecomerce from "../../assets/e-comerce.png";
import movies from "../../assets/movies.png";
import twitter from "../../assets/twitter.png";
import piggy from "../../assets/Piggy.png";
import styles from "./Projects.css";

function Projects() {
  const [projectInfo, setProjectInfo] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="row mb-5">
      <h2 className="">Projects</h2>
      <p className="mb-0">
        these are some of the projects I have been working on recently
      </p>
      <p id="details" className="">
        (click on the card to see more details)
      </p>

      <div
        className="project-zoom project-one col-lg-4  my-2 ms-2 d-flex flex-column align-items-center  project-title rounded shadow"
        style={{ width: "20rem", height: "20rem" }}
      >
        <img
          className="card-img-top rounded "
          src={piggy}
          alt="image-site"
          style={{ width: "95%", aspectRatio: "521/410" }}
        />
        <div className="text-center project-two">
          <h3 className="mb-0 h4 mt-3 ">Expenses Control App</h3>
          <div className="py-4">
            <button
              className="btn  generic-button px-5 py-2 mt-5"
              onClick={() => {
                setProjectInfo({
                  title: "Expenses Control App",
                  imag: piggy,
                  description:
                    "Application for personal budget management. It allow to create and edit income and expenses of money, and to show a balance sheet resulting from the recorded registered operations.",
                  tools:
                    "React, Nodejs, Redux, MySQL, Supabase, JavaScript, Boostrap",
                  description2:
                    "User authentication, a registration and login form that allow to identify the user using the application,Categories of operations,etc. ",
                  url: "https://piggysafe.vercel.app/",
                  aspectRatio: "521/410",
                });
                handleShow();
              }}
            >
              {" "}
              See More
            </button>
          </div>
        </div>
      </div>

      <div
        className="project-zoom project-one col-lg-4  my-2  ms-2 d-flex flex-column align-items-center  project-title rounded shadow"
        style={{ width: "20rem", height: "20rem" }}
      >
        <img
          className="card-img-top rounded "
          src={ecomerce}
          alt="image-site"
          style={{ width: "95%" }}
        />
        <div className="text-center project-two">
          <h3 className="mb-0 h4 mt-3">E-comerce</h3>
          <div className="py-4">
            <button
              className="btn  generic-button px-5 py-2 mt-5"
              onClick={() => {
                setProjectInfo({
                  title: "Ecomerce",
                  imag: ecomerce,
                  description:
                    "The realization of this project was carried out as the final work of the Coding Bootcamp of March 2022 at Hack Academy. The project consisted of making a free choice ecommerce with a duration of 3 weeks for its execution.",
                  tools: "React, Nodejs, Redux, MongoDB, JavaScript",
                  description2:
                    "it has 3 projects in one, an API REST (Back-end), an Ecomerce(fron-end) and also have a dashboard(CRUD)",
                  url: "https://hackskull.vercel.app/",
                  aspectRatio: "115/98",
                });
                handleShow();
              }}
            >
              {" "}
              See More
            </button>
          </div>
        </div>
      </div>

      <div
        className="project-zoom project-one col-lg-4  my-2 ms-2 d-flex flex-column align-items-center  project-title rounded shadow"
        style={{ width: "20rem", height: "20rem" }}
      >
        <img
          className="card-img-top rounded "
          src={twitter}
          alt="image-site"
          style={{ width: "95%" }}
        />
        <div className="text-center project-two">
          <h3 className="mb-0 h4 mt-3 ">Twitter Clone</h3>
          <div className="py-4">
            <button
              className="btn  generic-button px-5 py-2 mt-5"
              onClick={() => {
                setProjectInfo({
                  title: "Twitter Clone",
                  imag: twitter,
                  description:
                    "This is a Twitter clone and it's has some utilites, you can log-in/out follow some friends, tweet something, like tweets, etc.",
                  tools: "React, Nodejs, Redux, MongoDB, JavaScript, Boostrap",
                  description2: "",
                  url: "https://twitter-react-redux-clone.vercel.app/",
                  aspectRatio: "973/848",
                });
                handleShow();
              }}
            >
              {" "}
              See More
            </button>
          </div>
        </div>
      </div>

      <div
        className="project-zoom project-one col-lg-4 my-2 ms-2 d-flex flex-column align-items-center  project-title rounded shadow"
        style={{ width: "20rem", height: "20rem" }}
      >
        <img
          className="card-img-top rounded "
          src={movies}
          alt="image-site"
          style={{ width: "95%" }}
        />
        <div className=" text-center project-two">
          <h3 className="mb-0 h4 mt-3 ">Movies Site</h3>
          <div className="py-4">
            <button
              className="btn generic-button px-5 py-2 mt-5"
              onClick={() => {
                setProjectInfo({
                  title: "Movies Site",
                  imag: movies,
                  description:
                    "In this site you can filter your favourites movies by rating and also by title and see some information about the movie you are looking for",
                  tools: "React, Nodejs, Redux, MongoDB, JavaScript, Boostrap",
                  description2:
                    "By defect it's show the most popular movies at this time",
                  url: "https://videohack.vercel.app/",
                  aspectRatio: "521/443",
                });
                handleShow();
              }}
            >
              {" "}
              See More
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="row "
      >
        <Modal.Header closeButton className="modal-background">
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="text-white">{projectInfo.title}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col lg={8}>
                <img
                  src={projectInfo.imag}
                  style={{
                    height: "100%",
                    width: "100%",
                    aspectRatio: projectInfo?.aspectRatio,
                  }}
                  className="shadow"
                />
              </Col>
              <Col lg={4}>
                <p className="mt-1">{projectInfo.description}</p>
                <p>{projectInfo.description2}</p>
                <p>{projectInfo.tools}</p>
              </Col>
            </Row>

            <Row></Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="modal-background">
          <a
            className="btn  generic-button"
            href={`${projectInfo.url}`}
            target="_blank"
          >
            Live Demo!
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Projects;
