import React from "react";
import StackSlider from "../StackSlider/StackSlider";
import Pablo from "../../assets/Pablo2.jpg";
function AboutMe() {
  return (
    <div>
      <div className="row mb-5 mt-5">
        <div className="col-lg-3 border p-3">
          {" "}
          <div className="d-flex flex-column align-items-center">
            <img
              src={Pablo}
              alt="avatar"
              className="rounded-circle avatar  shadow my-2 "
              width={150}
            ></img>
            <h3 className="mt-2 fw-bold h5">Pablo Marrero</h3>
            <h4 className="h5">Full Stack Developer</h4>
            <div className="d-flex">
              <a
                href="https://www.linkedin.com/in/marrero-pablo/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i className="bi bi-linkedin fs-2 i-link"></i>
              </a>{" "}
              <a
                href="https://github.com/marreropd"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-github ms-3  fs-2 i-link"></i>
              </a>
            </div>
            <div className="">
              <h5 className="h6 mt-2"> Technologies and tools</h5>
              <ul className="small ">
                <li className="small">Java/AWS</li>
                <li className="small">HTML/CSS</li>
                <li className="small">JavaScript/Typescript</li>
                <li className="small">Node/React</li>
                <li className="small">Docker</li>
                <li className="small">MySQL/MongoDB/Postgres</li>
                <li className="small">Git/Github</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-9 border p-3">
          <p>
            Hi, I´m Pablo Six years ago I learned to assemble casino gaming
            machines and since then I developed a taste for technology that has
            not stopped growing. During my work experience I developed
            management and sales skills, while I learned to solve technical
            problems in a self-taught way, looking for the solutions by myself.
            I discovered that programming and maintenance of casino machines
            share some similarities. So I decided to learn web development. now
            a days I have been working as a developer for a while and i love it.
            In my free time I like to learn new things, hang out with friends,
            go to the beach or go for a run.
          </p>
          <hr />
          <h4>Last relevant Education</h4>
          <p>
            <b>SEAS, Open University Studies </b>
            Master's Degree in Multiplatform Application Development (DAM) June
            2023 - Present It includes disciplines such as programming, software
            engineering, quality, design and management of ICT projects. Lead,
            develop and implement desktop, web and mobile projects, ensuring
            data security and meeting the required quality standards.
          </p>
          <p>
            <b>National Technological University (BA) - UTN </b>
            Development with NodeJs - May 2022 - Ago 2022
          </p>
          <p>
            <b> Coding Bootcamp - Full Stack web development Hack Academy </b>{" "}
            January 2022 - April 2022 Extremely practical and intensive
            full-time educational program
          </p>
          <hr />
          <h5>Interests and hobbies</h5>
          <ul className="ms-0 small">
            <li className="ms-0">Beach Volley</li>
            <li>Strategy video games</li>
            <li>Investments</li>
            <li>Running</li>
          </ul>
        </div>
      </div>

      <div className="">
        <StackSlider />
      </div>
    </div>
  );
}

export default AboutMe;
