import React from "react";
import styles from "./ContactMe.css";
function ContactMe() {
  return (
    <div className="">
      <div>
        <h4 className="text-center fw-bold ">
          {" "}
          <i className="bi bi-star-fill "></i> GET IN TOUCH!{" "}
          <i className="bi bi-star-fill "></i>
        </h4>
        <hr />
      </div>

      <div className="d-flex align-items-center justify-content-center">
        <a
          href="https://www.linkedin.com/in/marrero-pablo/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <i className="bi bi-linkedin i-link me-4"></i>
        </a>{" "}
        <a href="https://github.com/marreropd" target="_blank" rel="noreferrer">
          <i className="bi bi-github ms-4 i-link"></i>
        </a>
      </div>
    </div>
  );
}

export default ContactMe;
