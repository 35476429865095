import "./App.css";
import SideBar from "./components/SideBar/SideBar";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css";
import TypeAnimation from "react-type-animation";
import Projects from "./components/Projects/Projects";
import Media from "react-media";
import { Fragment } from "react";
import Navbar from "./components/Navbar/Navbar";
import Slick from "./components/Slick/Slick";
import { Scroll } from "react-scroll/modules/mixins/Helpers";
import ScrollAnimation from "./components/Scroll/ScrollAnimation";
import AboutMe from "./components/AboutMe/AboutMe";
import ContactMe from "./components/ContactMe/ContactMe";
import logo from "./assets/logo.png";

function App() {
  return (
    <>
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) ",
        }}
      >
        {(matches) => <Fragment>{matches.small && <Navbar />}</Fragment>}
      </Media>
      <div className="container">
        <div className="row">
          <Media
            queries={{
              small: "(max-width: 599px)",
              medium: "(min-width: 600px) ",
            }}
          >
            {(matches) => (
              <Fragment>
                {matches.medium && (
                  <div className="">
                    <SideBar />
                  </div>
                )}
              </Fragment>
            )}
          </Media>

          <div
            id="sections"
            className="d-flex flex-column justify-content-center align-items-center  container"
          >
            <section
              id="Welcome"
              className="d-flex flex-column justify-content-center w-75"
            >
              <TypeAnimation
                cursor={true}
                sequence={[
                  "Welcome! I'm Pablo",
                  2000,
                  "Welcome! I love coding",
                  2000,
                  "Welcome! I love coffee",
                  2000,
                  "Welcome! I enjoy learning",
                  2000,
                  "Welcome! I hope to meet you!",
                  2000,
                  "Welcome! I'm Pablo",
                ]}
                wrapper="h1"
                repeat={3}
              />
              <AnimationOnScroll
                animateIn="animate__animated animate__flipInX "
                animatePreScroll={false}
              >
                {" "}
                <h3>Full Stack Developer</h3>
              </AnimationOnScroll>
              <ScrollAnimation />
            </section>

            <section
              id="Projects"
              className="d-flex flex-column align-items-center w-75"
            >
              <AnimationOnScroll
                animateIn="animate__animated animate__fadeIn "
                animatePreScroll={false}
              >
                <Media
                  queries={{
                    small: "(max-width: 599px)",
                    medium: "(min-width: 600px) ",
                  }}
                >
                  {(matches) => (
                    <Fragment>
                      {matches.small && <Slick />}
                      {matches.medium && <Projects />}
                    </Fragment>
                  )}
                </Media>
              </AnimationOnScroll>
            </section>

            <section
              id="About-Me"
              className="d-flex flex-column justify-content-center w-75 "
            >
              <AnimationOnScroll
                animateIn="animate__animated animate__fadeIn "
                animatePreScroll={false}
              >
                <AboutMe />
              </AnimationOnScroll>
            </section>

            <section
              id="Contact-Me"
              className="d-flex flex-column justify-content-center w-75"
            >
              <AnimationOnScroll
                animateIn="animate__animated animate__fadeIn "
                animatePreScroll={false}
              >
                <ContactMe />
              </AnimationOnScroll>
            </section>
          </div>
        </div>
      </div>
      <a href="https://www.linkedin.com/in/marrero-pablo" target={"_blank"}>
        <img id="logo" src={logo} height={60} />
      </a>
    </>
  );
}

export default App;
