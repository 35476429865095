import React from "react";
import styles from "./SideBar.css";
import { Link, animateScroll as scroll } from "react-scroll";

function SideBar() {
  return (
    <div id="" className="side-bar">
      <div
        className="d-flex side-bar justify-content-center flex-column position-fixed"
        style={{ height: "100vh" }}
      >
        <div className="mb-4">
          <Link
            activeClass="active"
            to="Welcome"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="SideBar-button"
          >
            <i className="bi bi-house-door-fill SideBar-icon"></i>
            <span className="SideBar-text">Welcome</span>
          </Link>
        </div>
        <div className="mb-4">
          <Link
            activeClass="active"
            to="Projects"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="SideBar-button"
          >
            {" "}
            <i className="bi bi-file-code-fill  SideBar-icon"></i>
            <span className="SideBar-text">Projects</span>
          </Link>
        </div>
        <div className="mb-4">
          <Link
            activeClass="active"
            to="About-Me"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="SideBar-button"
          >
            <i className="bi bi-file-person-fill SideBar-icon"></i>
            <span className="SideBar-text">About Me</span>
          </Link>
        </div>
        <div className="mb-4 ">
          <Link
            activeClass="active"
            to="Contact-Me"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="SideBar-button"
          >
            {" "}
            <i className="bi bi-chat-left-dots-fill SideBar-icon"></i>
            <span className="SideBar-text">Contact Me</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
