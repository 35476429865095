import React from "react";
import styles from "./StackSlider.css";
import css3 from "../../assets/iconos/css3.svg";
import html from "../../assets/iconos/html-5.svg";
import mongodb from "../../assets/iconos/mongodb.svg";
import nodejs from "../../assets/iconos/nodejs(.svg";
import react from "../../assets/iconos/react.svg";
import redux from "../../assets/iconos/redux.svg";
import vercel from "../../assets/iconos/vercel.svg";
import boostrap from "../../assets/iconos/bootstrap-5-1.svg";
import jwt from "../../assets/iconos/jwt-3.svg";
import javascript from "../../assets/iconos/javascript-1.svg";

function StackSlider() {
  return (
    <div>
      <div className="slider">
        <div className="slide-track ">
          <div className="slide">
            <img src={javascript} height="70" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={css3} height="80" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={html} height="80" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={mongodb} height="80" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={nodejs} height="80" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={react} height="80" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={redux} height="80" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={vercel} height="80" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={boostrap} height="80" width="250" alt="" />
          </div>
          <div className="slide">
            <img src={jwt} height="80" width="250" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StackSlider;
